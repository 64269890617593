.menu {
    cursor: pointer;
    text-decoration: none;
}

.menu:hover {
    font-weight: 700 !important;
}

.active {
    color: var(--primary-blue) !important;
    font-weight: 700 !important;
}

.solucoes {
    background-color: var(--background-medium);
}

.boost {
    background-color: var(--default-white);
    box-shadow: 0px 0px 6px rgba(136, 167, 188, 0.3);
    border-radius: 6px;
    padding: 32px;
}